import { Routes, Route, Link, Outlet } from "react-router-dom";
import FinalizedUpdate from "./Finalized";
import Home from "./Home";
import OptimisticUpdate from "./Optimistic";
import HistoricalUpdates from "./HistoricalUpdates";
import SyncCommitteeUpdate from "./SyncCommittee";
import LightClientState from "./LightClientState";

export default function App() {
  return (
    <Routes>
      <Route path="lightClient" element={<LightClientState />} />
      <Route path="overview" element={<HistoricalUpdates />} />
      <Route path="finalizedUpdate/:slot" element={<FinalizedUpdate />} />
      <Route
        path="syncCommitteeUpdate/:slot"
        element={<SyncCommitteeUpdate />}
      />
      <Route path="optimisticUpdate/:slot" element={<OptimisticUpdate />} />
      <Route path="/" element={<Home />}></Route>
    </Routes>
  );
}
