export const ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "attestedHeader",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "finalizedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32[]",
            name: "finalityBranch",
            type: "bytes32[]",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "bitsSum",
                type: "uint256",
              },
            ],
            internalType: "struct ValidHeaderProof",
            name: "headerProof",
            type: "tuple",
          },
        ],
        internalType: "struct LightClientFinalizedUpdate",
        name: "update",
        type: "tuple",
      },
      {
        internalType: "bytes32",
        name: "finalizedBlockRoot",
        type: "bytes32",
      },
    ],
    name: "assertValidFinalityProof",
    outputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "attestedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "nextSyncCommitteeSSZ",
            type: "bytes32",
          },
          {
            internalType: "bytes32[]",
            name: "nextSyncCommitteeBranch",
            type: "bytes32[]",
          },
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "finalizedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32[]",
            name: "finalityBranch",
            type: "bytes32[]",
          },
          {
            internalType: "bytes32",
            name: "forkVersion",
            type: "bytes32",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "bitsSum",
                type: "uint256",
              },
            ],
            internalType: "struct ValidHeaderProof",
            name: "headerProof",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "syncCommitteePoseidon",
                type: "uint256",
              },
            ],
            internalType: "struct SyncCommitteeCommittmentsProof",
            name: "syncCommitteeCommitmentsProof",
            type: "tuple",
          },
        ],
        internalType: "struct LightClientUpdate",
        name: "update",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "isFinalized",
        type: "bool",
      },
    ],
    name: "assertValidLightClientUpdate",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint64",
            name: "slot",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "proposerIndex",
            type: "uint64",
          },
          {
            internalType: "bytes32",
            name: "parentRoot",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "stateRoot",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "bodyRoot",
            type: "bytes32",
          },
        ],
        internalType: "struct BeaconBlockHeader",
        name: "header",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256[2]",
            name: "a",
            type: "uint256[2]",
          },
          {
            internalType: "uint256[2][2]",
            name: "b",
            type: "uint256[2][2]",
          },
          {
            internalType: "uint256[2]",
            name: "c",
            type: "uint256[2]",
          },
          {
            internalType: "uint256",
            name: "bitsSum",
            type: "uint256",
          },
        ],
        internalType: "struct ValidHeaderProof",
        name: "proof",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "syncCommitteePoseidon",
        type: "uint256",
      },
    ],
    name: "assertValidSignedHeader",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256[2]",
            name: "a",
            type: "uint256[2]",
          },
          {
            internalType: "uint256[2][2]",
            name: "b",
            type: "uint256[2][2]",
          },
          {
            internalType: "uint256[2]",
            name: "c",
            type: "uint256[2]",
          },
          {
            internalType: "uint256",
            name: "syncCommitteePoseidon",
            type: "uint256",
          },
        ],
        internalType: "struct SyncCommitteeCommittmentsProof",
        name: "syncCommitteeCommitmentsProof",
        type: "tuple",
      },
      {
        internalType: "bytes32",
        name: "nextSyncCommitteeSSZ",
        type: "bytes32",
      },
    ],
    name: "assertValidSyncCommitteeCommittment",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "attestedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "nextSyncCommitteeSSZ",
            type: "bytes32",
          },
          {
            internalType: "bytes32[]",
            name: "nextSyncCommitteeBranch",
            type: "bytes32[]",
          },
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "finalizedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32[]",
            name: "finalityBranch",
            type: "bytes32[]",
          },
          {
            internalType: "bytes32",
            name: "forkVersion",
            type: "bytes32",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "bitsSum",
                type: "uint256",
              },
            ],
            internalType: "struct ValidHeaderProof",
            name: "headerProof",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "syncCommitteePoseidon",
                type: "uint256",
              },
            ],
            internalType: "struct SyncCommitteeCommittmentsProof",
            name: "syncCommitteeCommitmentsProof",
            type: "tuple",
          },
        ],
        internalType: "struct LightClientUpdate",
        name: "update",
        type: "tuple",
      },
      {
        internalType: "bytes32",
        name: "activeStateRoot",
        type: "bytes32",
      },
    ],
    name: "assertValidSyncCommitteeInclusion",
    outputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "b",
        type: "bytes32",
      },
    ],
    name: "bytes32ToUint256Array",
    outputs: [
      {
        internalType: "uint256[32]",
        name: "",
        type: "uint256[32]",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "finalized",
    outputs: [
      {
        internalType: "uint64",
        name: "participation",
        type: "uint64",
      },
      {
        components: [
          {
            internalType: "uint64",
            name: "slot",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "proposerIndex",
            type: "uint64",
          },
          {
            internalType: "bytes32",
            name: "parentRoot",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "stateRoot",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "bodyRoot",
            type: "bytes32",
          },
        ],
        internalType: "struct BeaconBlockHeader",
        name: "header",
        type: "tuple",
      },
      {
        internalType: "bytes32",
        name: "blockRoot",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "head",
    outputs: [
      {
        internalType: "uint64",
        name: "participation",
        type: "uint64",
      },
      {
        components: [
          {
            internalType: "uint64",
            name: "slot",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "proposerIndex",
            type: "uint64",
          },
          {
            internalType: "bytes32",
            name: "parentRoot",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "stateRoot",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "bodyRoot",
            type: "bytes32",
          },
        ],
        internalType: "struct BeaconBlockHeader",
        name: "header",
        type: "tuple",
      },
      {
        internalType: "bytes32",
        name: "blockRoot",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "isFinalized",
            type: "bool",
          },
          {
            internalType: "uint64",
            name: "participation",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "slot",
            type: "uint64",
          },
        ],
        internalType: "struct LightClientUpdateStats",
        name: "prev",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bool",
            name: "isFinalized",
            type: "bool",
          },
          {
            internalType: "uint64",
            name: "participation",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "slot",
            type: "uint64",
          },
        ],
        internalType: "struct LightClientUpdateStats",
        name: "next",
        type: "tuple",
      },
    ],
    name: "isBetterUpdate",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "a",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "b",
        type: "uint64",
      },
    ],
    name: "max",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "maxParticipationByPeriod",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nextSyncCommitteeStats",
    outputs: [
      {
        internalType: "bool",
        name: "isFinalized",
        type: "bool",
      },
      {
        internalType: "uint64",
        name: "participation",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "slot",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "period",
        type: "uint64",
      },
      {
        internalType: "uint256",
        name: "poseidon",
        type: "uint256",
      },
    ],
    name: "overrideSyncCommitteePoseidon",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "attestedHeader",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "finalizedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32[]",
            name: "finalityBranch",
            type: "bytes32[]",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "bitsSum",
                type: "uint256",
              },
            ],
            internalType: "struct ValidHeaderProof",
            name: "headerProof",
            type: "tuple",
          },
        ],
        internalType: "struct LightClientFinalizedUpdate",
        name: "update",
        type: "tuple",
      },
    ],
    name: "processFinalizedUpdate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "attestedHeader",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "bitsSum",
                type: "uint256",
              },
            ],
            internalType: "struct ValidHeaderProof",
            name: "headerProof",
            type: "tuple",
          },
        ],
        internalType: "struct LightClientOptimisticUpdate",
        name: "update",
        type: "tuple",
      },
    ],
    name: "processOptimisticUpdate",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "attestedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "nextSyncCommitteeSSZ",
            type: "bytes32",
          },
          {
            internalType: "bytes32[]",
            name: "nextSyncCommitteeBranch",
            type: "bytes32[]",
          },
          {
            components: [
              {
                internalType: "uint64",
                name: "slot",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "proposerIndex",
                type: "uint64",
              },
              {
                internalType: "bytes32",
                name: "parentRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "stateRoot",
                type: "bytes32",
              },
              {
                internalType: "bytes32",
                name: "bodyRoot",
                type: "bytes32",
              },
            ],
            internalType: "struct BeaconBlockHeader",
            name: "finalizedHeader",
            type: "tuple",
          },
          {
            internalType: "bytes32[]",
            name: "finalityBranch",
            type: "bytes32[]",
          },
          {
            internalType: "bytes32",
            name: "forkVersion",
            type: "bytes32",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "bitsSum",
                type: "uint256",
              },
            ],
            internalType: "struct ValidHeaderProof",
            name: "headerProof",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint256[2]",
                name: "a",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2][2]",
                name: "b",
                type: "uint256[2][2]",
              },
              {
                internalType: "uint256[2]",
                name: "c",
                type: "uint256[2]",
              },
              {
                internalType: "uint256",
                name: "syncCommitteePoseidon",
                type: "uint256",
              },
            ],
            internalType: "struct SyncCommitteeCommittmentsProof",
            name: "syncCommitteeCommitmentsProof",
            type: "tuple",
          },
        ],
        internalType: "struct LightClientUpdate",
        name: "update",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "isFinalized",
        type: "bool",
      },
    ],
    name: "processSyncCommitteeUpdate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "period",
        type: "uint64",
      },
      {
        internalType: "uint256",
        name: "poseidon",
        type: "uint256",
      },
    ],
    name: "setSyncCommitteePoseidon",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "syncCommitteePoseidonByPeriod",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "syncCommitteeSSZByPeriod",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
