import ReactJson from "react-json-view";
import { useState, useEffect } from "react";
import axios from "axios";

const API = "https://lodestar-mainnet.chainsafe.io";
const OPTIMISTIC_UPDATE_ROUTE = "/eth/v1/light_client/optimistic_update/";
const FINALIZED_UPDATE_ROUTE = "/eth/v1/light_client/finality_update/";
const LIGHT_CLIENT_UPDATE_ROUTE =
  "/eth/v1/light_client/updates?start_period=525&count=1";

type Hex = String;
type Bit = Number;
type BLSSignature = String;

interface AttestedHeader {
  slot: number;
  proposer_index: number;
  parent_root: Hex;
  state_root: Hex;
  body_root: Hex;
}

interface SyncAggregate {
  sync_committee_bits: Bit[];
  sync_committee_signature: BLSSignature;
}

export interface OptimisticHeader {
  attested_header: AttestedHeader;
  sync_aggregate: SyncAggregate;
}

interface FinalizedHeader {
  attested_header: AttestedHeader;
  finalized_header: AttestedHeader;
  finality_branch: Hex[];
  sync_aggregate: SyncAggregate;
}

interface SyncCommittee {
  pubkeys: Hex[];
  aggregate_pubkey: Hex;
}

export async function getOptimisticHeader(): Promise<OptimisticHeader> {
  const res = await axios.get(API + OPTIMISTIC_UPDATE_ROUTE);
  const attested_header: AttestedHeader = {
    slot: Number(res.data.data.attested_header.slot),
    proposer_index: Number(res.data.data.attested_header.proposer_index),
    parent_root: res.data.data.attested_header.parent_root,
    state_root: res.data.data.attested_header.state_root,
    body_root: res.data.data.attested_header.body_root,
  };
  const sync_aggregate: SyncAggregate = {
    sync_committee_bits: BigInt(
      res.data.data.sync_aggregate.sync_committee_bits
    )
      .toString(2)
      .split("")
      .map(Number),
    sync_committee_signature:
      res.data.data.sync_aggregate.sync_committee_signature,
  };

  const optimistic_header: OptimisticHeader = {
    attested_header: attested_header,
    sync_aggregate: sync_aggregate,
  };
  return optimistic_header;
}

async function getFinalizedHeader(): Promise<FinalizedHeader> {
  const res = await axios.get(API + FINALIZED_UPDATE_ROUTE);
  const attested_header: AttestedHeader = {
    slot: res.data.data.attested_header.slot,
    proposer_index: res.data.data.attested_header.proposer_index,
    parent_root: res.data.data.attested_header.parent_root,
    state_root: res.data.data.attested_header.state_root,
    body_root: res.data.data.attested_header.body_root,
  };
  const inner_finalized_header: AttestedHeader = {
    slot: res.data.data.finalized_header.slot,
    proposer_index: res.data.data.finalized_header.proposer_index,
    parent_root: res.data.data.finalized_header.parent_root,
    state_root: res.data.data.finalized_header.state_root,
    body_root: res.data.data.finalized_header.body_root,
  };
  const sync_aggregate: SyncAggregate = {
    sync_committee_bits: BigInt(
      res.data.data.sync_aggregate.sync_committee_bits
    )
      .toString(2)
      .split("")
      .map(Number),
    sync_committee_signature:
      res.data.data.sync_aggregate.sync_committee_signature,
  };
  const finalized_header: FinalizedHeader = {
    attested_header: attested_header,
    finalized_header: inner_finalized_header,
    finality_branch: res.data.data.finality_branch,
    sync_aggregate: sync_aggregate,
  };
  return finalized_header;
}

async function getSyncCommittee(): Promise<SyncCommittee> {
  const res = await axios.get(API + LIGHT_CLIENT_UPDATE_ROUTE);
  console.log("test", res);
  const sync_committee: SyncCommittee = {
    pubkeys: res.data.data[0].next_sync_committee.pubkeys,
    aggregate_pubkey: res.data.data[0].next_sync_committee.aggregate_pubkey,
  };
  return sync_committee;
}

export default function BeaconChainState() {
  const [optimisticHeader, setOptimisticHeader] = useState<OptimisticHeader>();
  const [finalizedHeader, setFinalizedHeader] = useState<FinalizedHeader>();
  const [syncCommittee, setSyncCommittee] = useState<SyncCommittee>();

  useEffect(() => {
    getOptimisticHeader().then((x: OptimisticHeader) => {
      setOptimisticHeader(x);
    });
    getFinalizedHeader().then((x: FinalizedHeader) => {
      setFinalizedHeader(x);
    });
    getSyncCommittee().then((x: SyncCommittee) => {
      setSyncCommittee(x);
    });
  }, []);

  return (
    <div>
      <h3>ETH2 Beacon Chain State</h3>
      <h4>
        Optimistic Header (Slot&nbsp;
        {optimisticHeader?.attested_header.slot ?? "undefined"})
      </h4>
      <ReactJson
        src={optimisticHeader === undefined ? {} : optimisticHeader}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      />
      <h4>
        Finalized Header (Slot&nbsp;
        {finalizedHeader?.finalized_header.slot ?? "undefined"})
      </h4>
      <ReactJson
        src={finalizedHeader === undefined ? {} : finalizedHeader}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      />
      <h4>Sync Committee (Period 525)</h4>
      <ReactJson
        src={syncCommittee === undefined ? {} : syncCommittee}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      />
    </div>
  );
}
