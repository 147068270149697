import BeaconChainState from "./BeaconChainState";
import Header from "./Header";
import LightClientState from "./LightClientState";
import HistoricalUpdates from "./HistoricalUpdates";

function Home() {
  return (
    <div className="App">
      <Header></Header>
      <BeaconChainState></BeaconChainState>
      <LightClientState></LightClientState>
      <HistoricalUpdates></HistoricalUpdates>

      {/* <h3 style={{ marginTop: "35px" }}> Light Client Updates</h3>
      Fill out the form below to update the light client with the latest
      finalized block header signed by the sync committee. On the backend, we
      will generate a zero knowledge proof that proves the validity of the
      aggregated BLS signature and other serialization-related work.
      <br></br>
      <p style={{ color: "blue" }}>
        New light client update available for slot 4401086! Click{" "}
        <a href="https://google.com">here</a> to submit transaction.
      </p>
      <ul>
        <li>
          [{new Date().toLocaleString()}] Slot 4301108 -{" "}
          <code>0x4056d476b10385d793950581cce894778e127d32</code>
        </li>
        <li>
          [{new Date().toLocaleString()}] Slot 4301108 -{" "}
          <code>0x4056d476b10385d793950581cce894778e127d32</code>
        </li>
        <li>
          [{new Date().toLocaleString()}] Slot 4301108 -{" "}
          <code>0x4056d476b10385d793950581cce894778e127d32</code>
        </li>
        <li>
          [{new Date().toLocaleString()}] Slot 4301108 -{" "}
          <code>0x4056d476b10385d793950581cce894778e127d32</code>
        </li>
      </ul> */}
    </div>
  );
}

export default Home;
