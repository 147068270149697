import { useEffect, useState } from "react";
import axios from "axios";

export default function HistoricalUpdates() {
  const [optimisticSlots, setOSlots] = useState<string[]>();
  const [finalizedSlots, setFSlots] = useState<string[]>();
  const [syncCommitteeSlots, setSSlots] = useState<string[]>();

  useEffect(() => {
    axios.get(`http://localhost:5001/get/optimistic`).then((res) => {
      console.log(res.data);
      setOSlots(res.data.slots);
    });
    axios.get(`http://localhost:5001/get/finalized`).then((res) => {
      console.log(res.data);
      setFSlots(res.data.slots);
    });
    axios.get(`http://localhost:5001/get/syncCommittee`).then((res) => {
      console.log(res.data);
      setSSlots(res.data.slots);
    });
  }, []);

  return (
    <div>
      <h3>Historical Updates</h3>
      <h4>Optimistic Headers</h4>
      {optimisticSlots &&
        optimisticSlots?.map((slot) => (
          <div>
            <a href={`/optimisticUpdate/${slot}`} key={slot}>
              Slot {slot}
            </a>
          </div>
        ))}
      <h4>Finalized Headers</h4>
      {finalizedSlots &&
        finalizedSlots?.map((slot) => (
          <div>
            <a href={`/finalizedUpdate/${slot}`} key={slot}>
              Slot {slot}
            </a>
          </div>
        ))}
      <h4>Sync Committee</h4>
      {syncCommitteeSlots &&
        syncCommitteeSlots?.map((slot) => (
          <div>
            <a href={`/syncCommitteeUpdate/${slot}`} key={slot}>
              Slot {slot}
            </a>
          </div>
        ))}
    </div>
  );
}
