export type BeaconBlockHeader = {
  slot: number;
  proposerIndex: number;
  parentRoot: string;
  stateRoot: string;
  bodyRoot: string;
};

export type SyncAggregate = {
  syncCommitteeBits: string;
  syncCommitteeSignature: string;
};

export type ValidHeaderProof = {
  a: [string, string];
  b: [[string, string], [string, string]];
  c: [string, string];
  bitsSum: string;
};

export type SyncCommitteeCommittmentsProof = {
  a: [string, string];
  b: [[string, string], [string, string]];
  c: [string, string];
  syncCommitteePoseidon: string;
};

export type OptimisticUpdateResponse = {
  attestedHeader: BeaconBlockHeader;
  syncAggregate: SyncAggregate;
};

export type SolidityLightClientOptimisticUpdate = {
  attestedHeader: BeaconBlockHeader;
  headerProof: ValidHeaderProof;
};

export type FinalizedUpdateResponse = {
  attestedHeader: BeaconBlockHeader;
  finalizedHeader: BeaconBlockHeader;
  syncAggregate: SyncAggregate;
  finalityBranch: string[]; // length 6
};

export type SolidityLightClientFinalizedUpdate = {
  attestedHeader: BeaconBlockHeader;
  finalizedHeader: BeaconBlockHeader;
  finalityBranch: string[]; // length 6
  headerProof: ValidHeaderProof;
};

export type SyncCommitteeUpdateResponse = {
  attestedHeader: BeaconBlockHeader;
  syncAggregate: SyncAggregate;
  finalizedHeader: BeaconBlockHeader;
  finalityBranch: string[]; // length 6
  forkVersion: string;
  nextSyncCommittee: {
    aggregatePubkey: string;
    pubkeys: string[];
  };
  nextSyncCommitteeBranch: string[]; // length 5
};

export type SolidityLightClientSyncCommitteeUpdate = {
  attestedHeader: BeaconBlockHeader;
  nextSyncCommitteeSSZ: string;
  nextSyncCommitteeBranch: string[]; // length 5
  finalizedHeader: BeaconBlockHeader;
  finalityBranch: string[]; // length 6
  forkVersion: string;
  headerProof: ValidHeaderProof;
  syncCommitteeCommitmentsProof: SyncCommitteeCommittmentsProof;
};

export type ProofResponse = {
  pi_a: [string, string, string];
  pi_b: [[string, string], [string, string], [string, string]];
  pi_c: [string, string, string];
};

export type PublicResponse = string[]; // of length 34

export function headerProofResponseToSolidity(
  proof: ProofResponse,
  publics: PublicResponse
): ValidHeaderProof {
  return {
    a: [proof.pi_a[0], proof.pi_a[1]],
    b: [
      [proof.pi_b[0][1], proof.pi_b[0][0]],
      [proof.pi_b[1][1], proof.pi_b[1][0]],
    ],
    c: [proof.pi_c[0], proof.pi_c[1]],
    bitsSum: publics[0],
  };
}

export function syncCommitteeProofResponseToSolidity(
  proof: ProofResponse,
  publics: PublicResponse
): SyncCommitteeCommittmentsProof {
  return {
    a: [proof.pi_a[0], proof.pi_a[1]],
    b: [
      [proof.pi_b[0][1], proof.pi_b[0][0]],
      [proof.pi_b[1][1], proof.pi_b[1][0]],
    ],
    c: [proof.pi_c[0], proof.pi_c[1]],
    syncCommitteePoseidon: publics[32],
  };
}
