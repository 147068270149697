import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactJson from "react-json-view";
import Header from "./Header";
import {
  SyncCommitteeUpdateResponse,
  ProofResponse,
  PublicResponse,
  SolidityLightClientSyncCommitteeUpdate,
  headerProofResponseToSolidity,
  syncCommitteeProofResponseToSolidity,
} from "./types";
import { useContractRead, useContractWrite } from "wagmi";
import { ABI } from "./abi";
import { LIGHT_CLIENT_ADDRESS } from "./solidity";

const hexes = Array.from({ length: 256 }, (v, i) =>
  i.toString(16).padStart(2, "0")
);
export function bytesToHex(uint8a: string[]): string {
  // pre-caching chars could speed this up 6x.
  let hex = "0x";
  for (let i = 0; i < uint8a.length; i++) {
    hex += hexes[parseInt(uint8a[i])];
  }
  return hex;
}

export default function SyncCommitteeUpdate() {
  let params = useParams();
  const period = params.slot ?? 0; // this is actually the period
  const { data: currentPoseidon } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "syncCommitteePoseidonByPeriod",
    args: period,
  });
  const [update, setUpdate] = useState<SyncCommitteeUpdateResponse>();
  const [proofInputs, setProofInputs] = useState<any>(); // just for display, doesn't need types
  const [headerProof, setHeaderProof] = useState<ProofResponse>();
  const [headerPublic, setHeaderPublic] = useState<PublicResponse>();
  const [syncCommitteeProof, setSCProof] = useState<ProofResponse>();
  const [syncCommitteePublic, setSCPublic] = useState<PublicResponse>();
  const [solidityCalldata, setSolididtyCallData] =
    useState<SolidityLightClientSyncCommitteeUpdate>();
  const {
    data,
    isError,
    isLoading,
    write: writePoseidon,
  } = useContractWrite({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "overrideSyncCommitteePoseidon",
    args: [period, headerPublic ? headerPublic[1] : 0],
  });
  const { write: processSyncCommitteeUpdate } = useContractWrite({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "processSyncCommitteeUpdate",
    args: [solidityCalldata, true],
  });

  useEffect(() => {
    if (!params.slot) {
      return;
    }
    axios
      .get(`http://localhost:5001/get/syncCommittee/${params.slot}`)
      .then((res) => {
        const {
          periodUpdate: update,
          proofValidHeaderInputs,
          validHeader_proof,
          validHeader_public,
          syncCommitteeCommitment_proof: sc_proof, // TODO rename this to what file is
          syncCommitteeCommitment_public: sc_public,
        } = res.data;
        console.log(res.data);
        setUpdate(update);
        setProofInputs(proofValidHeaderInputs);
        if (validHeader_proof) {
          setHeaderProof(validHeader_proof);
        }
        if (validHeader_public) {
          setHeaderPublic(validHeader_public);
        }
        if (sc_proof) {
          setSCProof(sc_proof);
        }
        if (sc_public) {
          setSCPublic(sc_public);
        }
      });
  }, [params.slot]);

  function generateSolidityCall() {
    if (
      !headerProof ||
      !headerPublic ||
      !update ||
      !syncCommitteeProof ||
      !syncCommitteePublic ||
      !proofInputs
    )
      return;
    const solidityUpdate: SolidityLightClientSyncCommitteeUpdate = {
      attestedHeader: update.attestedHeader,
      nextSyncCommitteeSSZ: bytesToHex(syncCommitteePublic.slice(0, 32)),
      nextSyncCommitteeBranch: update.nextSyncCommitteeBranch,
      finalizedHeader: update.finalizedHeader,
      finalityBranch: update.finalityBranch,
      forkVersion: "0x".concat(update.forkVersion.slice(2).padStart(64, "0")),
      headerProof: headerProofResponseToSolidity(headerProof, headerPublic),
      syncCommitteeCommitmentsProof: syncCommitteeProofResponseToSolidity(
        syncCommitteeProof,
        syncCommitteePublic
      ),
    };
    console.log(solidityUpdate);
    setSolididtyCallData(solidityUpdate);
  }

  function sendSolidityUpdate() {
    if (!params.slot) return;
    // Check if contract has no syncCommitteePoseidon for given period, if so update it
    // Then send a call to `processOptimisticUpdate`
    if (currentPoseidon?.toString() === "0") {
      console.log("calling writePoseidon");
      writePoseidon();
    }
    console.log("Calling process optimistic update");
    processSyncCommitteeUpdate();
  }

  return (
    <div>
      <Header></Header>
      <h3>Sync Committee Update Information at Slot {params.slot}</h3>
      <h4>Sync Committee Update</h4>
      <ReactJson
        src={update === undefined ? {} : update}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      />
      <h4>Valid Signed Header Proof Inputs</h4>
      <ReactJson
        src={proofInputs === undefined ? {} : proofInputs}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      />
      {headerProof && (
        <div>
          <h4>Valid Header Proof</h4>
          <ReactJson
            src={headerProof}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
        </div>
      )}
      {headerPublic && (
        <div>
          <h4>Valid Header Proof Public Outputs</h4>
          <ReactJson
            src={headerPublic}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
        </div>
      )}
      {syncCommitteeProof && (
        <div>
          <h4>Sync Committee Proof</h4>
          <ReactJson
            src={syncCommitteeProof}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
        </div>
      )}
      {syncCommitteePublic && (
        <div>
          <h4>Sync Committee Proof Public Inputs</h4>
          <ReactJson
            src={syncCommitteePublic}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
        </div>
      )}

      <br></br>
      <br></br>
      {headerProof && syncCommitteePublic ? (
        <button onClick={generateSolidityCall}>Generate Solidity Update</button>
      ) : (
        <div>
          "We do not have the proofs generated for this update. Please wait for
          the proof to be generated before submitting update to the ZK Light
          Client smart contract."
        </div>
      )}
      {solidityCalldata && (
        <div>
          <h4>Solidity Call Data</h4>
          <ReactJson
            src={solidityCalldata}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
          <br></br>
          <br></br>
          <button onClick={sendSolidityUpdate}>Send Solidity Update</button>
        </div>
      )}
    </div>
  );
}
