import ReactJson from "react-json-view";
import { useContractRead } from "wagmi";
import { ABI } from "./abi";
import { LIGHT_CLIENT_ADDRESS } from "./solidity";
import { useEffect } from "react";

export default function LightClientState() {
  const { data: syncCommitteeSSZByPeriod } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "syncCommitteeSSZByPeriod",
    args: "0",
  });
  const { data: syncCommitteePoseidonByPeriod } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "syncCommitteePoseidonByPeriod",
    args: "0",
  });
  const { data: headData, isError } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    chainId: 5,
    functionName: "head",
  });

  useEffect(() => {
    console.log("headData", headData);
    console.log(isError);
  }, [headData, isError]);
  const { data: finalizedData } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "finalized",
  });
  const { data: nextSyncCommitteeStats } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "nextSyncCommitteeStats",
  });
  const { data: maxParticipationByPeriod } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "maxParticipationByPeriod",
    args: "0",
  });

  return (
    <div>
      <h3>Light Client State</h3>
      <h4>Address</h4>
      <code>
        <a href={"https://goerli.etherscan.io/address/" + LIGHT_CLIENT_ADDRESS}>
          {LIGHT_CLIENT_ADDRESS}
        </a>
      </code>
      <h4>
        Light Client Head (Slot {headData ? headData.header[0].toString() : ""})
      </h4>
      <ReactJson
        src={headData ?? {}}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      ></ReactJson>
    </div>
  );
}
