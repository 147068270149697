import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

export default function Header() {
  const { address } = useAccount();
  const { connect, connectors, isLoading, pendingConnector } = useConnect({
    connector: new InjectedConnector(),
  });

  return (
    <div>
      <h1 className="font-bold">zk-light-client</h1>
      This is a demo of our on-chain Ethereum 2.0 light client, powered by zero
      knowledge proofs for gas cost reduction. This work is supported by 0xPARC
      and GnosisDAO. Read more{" "}
      <a href="https://snapshot.org/#/gnosis.eth/proposal/0x7575919c07e03bbe642b11938501bb6cbc542337f64763f94a00e04ed403cfba">
        {" "}
        here.
      </a>
      <br></br>
      <br></br>
      Connect Wallet:{" "}
      {connectors.map((connector) => (
        <button
          disabled={!connector.ready}
          key={connector.id}
          onClick={() => connect({ connector })}
        >
          {connector.name}
          {!connector.ready && " (unsupported)"}
          {isLoading &&
            connector.id === pendingConnector?.id &&
            " (connecting)"}
        </button>
      ))}
      <br></br>
      Wallet Address:{" "}
      <code>
        <a href={"https://goerli.etherscan.io/address/" + address}>{address}</a>
      </code>
      <br></br>
      <br></br>
    </div>
  );
}
