import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactJson from "react-json-view";
import Header from "./Header";
import {
  FinalizedUpdateResponse,
  ProofResponse,
  PublicResponse,
  SolidityLightClientFinalizedUpdate,
} from "./types";
import { useContractRead, useContractWrite } from "wagmi";
import { ABI } from "./abi";
import { LIGHT_CLIENT_ADDRESS } from "./solidity";

export default function FinalizedUpdate() {
  let params = useParams();
  const period = params.slot ? Math.floor(parseInt(params.slot) / 8192) : 0;
  const { data: currentPoseidon } = useContractRead({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "syncCommitteePoseidonByPeriod",
    args: period,
  });
  const [update, setUpdate] = useState<FinalizedUpdateResponse>();
  const [proofInputs, setProofInputs] = useState<any>(); // just for display, doesn't need types
  const [proof, setProof] = useState<ProofResponse>();
  const [publics, setPublic] = useState<PublicResponse>();
  const [solidityCalldata, setSolididtyCallData] =
    useState<SolidityLightClientFinalizedUpdate>();
  const {
    data,
    isError,
    isLoading,
    write: writePoseidon,
  } = useContractWrite({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "overrideSyncCommitteePoseidon",
    args: [period, publics ? publics[1] : 0],
  });
  const { write: processFinalizedUpdate } = useContractWrite({
    addressOrName: LIGHT_CLIENT_ADDRESS,
    contractInterface: ABI,
    functionName: "processFinalizedUpdate",
    args: solidityCalldata,
  });

  useEffect(() => {
    if (!params.slot) {
      return;
    }
    axios
      .get(`http://localhost:5001/get/finalized/${params.slot}`)
      .then((res) => {
        const {
          update,
          proofValidHeaderInputs,
          validHeader_proof,
          validHeader_public,
        } = res.data;
        console.log(res.data);
        setUpdate(update);
        setProofInputs(proofValidHeaderInputs);
        if (validHeader_proof) {
          setProof(validHeader_proof);
        }
        if (validHeader_public) {
          setPublic(validHeader_public);
        }
      });
  }, [params.slot]);

  function generateSolidityCall() {
    if (!proof || !update || !publics || !proofInputs) return;
    const solidityUpdate: SolidityLightClientFinalizedUpdate = {
      attestedHeader: update.attestedHeader,
      finalizedHeader: update.finalizedHeader,
      finalityBranch: update.finalityBranch,
      headerProof: {
        a: [proof.pi_a[0], proof.pi_a[1]],
        b: [
          [proof.pi_b[0][1], proof.pi_b[0][0]],
          [proof.pi_b[1][1], proof.pi_b[1][0]],
        ],
        c: [proof.pi_c[0], proof.pi_c[1]],
        bitsSum: publics[0],
      },
    };
    setSolididtyCallData(solidityUpdate);
  }

  function sendSolidityUpdate() {
    if (!params.slot) return;
    // Check if contract has no syncCommitteePoseidon for given period, if so update it
    // Then send a call to `processOptimisticUpdate`
    if (currentPoseidon?.toString() === "0") {
      console.log("calling writePoseidon");
      writePoseidon();
    }
    console.log("Calling process finalized update");
    processFinalizedUpdate();
  }

  return (
    <div>
      <Header></Header>
      <h3>Finalized Update Information at Slot {params.slot}</h3>
      <h4>Finalized Update</h4>
      <ReactJson
        src={update === undefined ? {} : update}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      />
      <h4>Valid Signed Header Proof Inputs</h4>
      <ReactJson
        src={proofInputs === undefined ? {} : proofInputs}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed={true}
      />
      {proof && (
        <div>
          <h4>Proof</h4>
          <ReactJson
            src={proof}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
        </div>
      )}
      {publics && (
        <div>
          <h4>Proof Public Outputs</h4>
          <ReactJson
            src={publics}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
        </div>
      )}

      <br></br>
      <br></br>
      {proof ? (
        <button onClick={generateSolidityCall}>Generate Solidity Update</button>
      ) : (
        <div>
          "We do not have the proofs generated for this update. Please wait for
          the proof to be generated before submitting update to the ZK Light
          Client smart contract."
        </div>
      )}
      {solidityCalldata && (
        <div>
          <h4>Solidity Call Data</h4>
          <ReactJson
            src={solidityCalldata}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            collapsed={true}
          />
          <br></br>
          <br></br>
          <button onClick={sendSolidityUpdate}>Send Solidity Update</button>
        </div>
      )}
    </div>
  );
}
